<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <div>

      <!-- Filters -->
      <audit-log-list-filters
        :date-filter.sync="dateFilter"
        :date-filter-value.sync="dateFilterValue"
        :clear-date.sync="clearDate"
        :resource-filter.sync="resourceFilter"
        :resource-options="resourceOptions"
        :action-filter.sync="actionFilter"
        :action-options="actionOptions"
        :staff-filter.sync="staffFilter"
        :staff-options="staffOptions"
      />

      <!-- Table Container Card -->
      <b-card
        :title="$t('Audit Logs')"
        class="mb-0"
      >

        <div class="mb-2 mt-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start pl-0 mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')"
                  @keyup.enter="search"
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refAuditLogListTable"
          class="position-relative"
          :items="fetchAuditLogs"
          responsive
          :fields="visibleFields"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          stacked="sm"
        >

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link
              :to="{ name: 'audit-log-view', params: { id: data.item.id } }"
              class="btn btn-outline-secondary btn-icon mr-1"
            >
              <feather-icon
                icon="EyeIcon"
                size="16"
              />
            </b-link>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalAuditLogs"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

    </div>

  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BFormInput, BTable,
  BPagination, BLink,
} from 'bootstrap-vue'
import vSelect from '@/libs/vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import AuditLogListFilters from './AuditLogListFilters.vue'
import storeModule from '../../../common/storeModule'
import useAuditLogList from './useAuditLogList'
import auditLogStoreModule from '../auditLogStoreModule'

export default {
  components: {
    AuditLogListFilters,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BLink,

    vSelect,
  },
  data() {
    return {
      staffOptions: [],
      tableColumns: [
        {
          key: 'id', label: this.$t('ID'), sortable: true, visible: false,
        },
        {
          key: 'table_name', label: 'Resource', sortable: true, visible: true,
        },
        {
          key: 'record_id', label: 'Record ID', sortable: true, visible: true,
        },
        {
          key: 'dml_action', label: 'Action', sortable: true, visible: true,
        },
        {
          key: 'dml_created_at', label: 'Time', sortable: true, visible: true,
        },
        {
          key: 'dml_created_by', label: 'User', sortable: true, visible: true,
        },
        {
          key: 'actions', label: this.$t('Detail'), visible: true,
        },
      ],
    }
  },
  computed: {
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'audit-log'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, auditLogStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const clearDate = null

    const resourceOptions = [
      { label: 'Sale', value: 'sale' },
      { label: 'Receipt', value: 'receipt' },
      { label: 'Issue', value: 'issue' },
      { label: 'Inventory Adjustment', value: 'inventory_adjustment' },
      { label: 'Purchase', value: 'purchase' },
    ]

    const actionOptions = [
      { label: 'DELETE', value: 'DELETE' },
      { label: 'UPDATE', value: 'UPDATE' },
      { label: 'INSERT', value: 'INSERT' },
    ]

    const {
      fetchAuditLogs,
      perPage,
      currentPage,
      totalAuditLogs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAuditLogListTable,
      refetchData,

      // Filters
      resourceFilter,
      staffFilter,
      actionFilter,
      dateFilter,
      dateFilterValue,

    } = useAuditLogList()

    return {

      fetchAuditLogs,
      perPage,
      currentPage,
      totalAuditLogs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAuditLogListTable,
      refetchData,
      clearDate,
      resourceOptions,
      actionOptions,
      // Extra Filters
      resourceFilter,
      staffFilter,
      actionFilter,
      dateFilter,
      dateFilterValue,

    }
  },
  mounted() {
    this.loadStaffs()
  },
  methods: {
    loadStaffs() {
      store
        .dispatch('common/fetchStaffs', { id_name: 1, audit: 1 })
        .then(response => {
          const { staffs } = response.data.data
          for (let i = 0; i < staffs.length; i += 1) {
            this.staffOptions.push({ label: staffs[i].staff_name, value: staffs[i].user_name })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.refetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
